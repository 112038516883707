import {FormCheck} from "react-bootstrap";
import React from "react";
import queryString from "query-string";
import parse from 'html-react-parser'

const styles = {
    cell: {
        backgroundColor: '#fff',
        whiteSpace: 'pre-line',
    },

    tableCell: {
        backgroundColor: '#fff',
    }
}

export default function TableCell(props) {
    console.log("TableCell props", props)

    let getTags = data => {
        let content = []

        if (Object.keys(data).length > 0) {
            for (let key in data) {
                let value = data[key]

                content.push(
                    <div key={key}>
                        <strong>{key}</strong>
                        <br />
                        {
                            value.map(tag => {
                                return(
                                    <div key={tag}>
                                        - {tag}
                                    </div>
                                )
                            })
                        }
                        <br />
                    </div>
                )
            }
        }

        return content
    }

    if (props.type === "input") {
        console.log("type input value", props.value)
        let cellValue = props.value ? props.value : ""

        return (
          <td contentEditable={true} suppressContentEditableWarning={true} style={styles.cell}
              onBlur={(e) =>
                props.oninputCell(props.column.code, props.rowCode, 'input', e)}>{parse(cellValue)}</td>
        );
    } else if (props.type === "checkbox") {
        return(
            <td contentEditable={false} className="center align-middle" style={styles.tableCell}>
                <FormCheck defaultChecked={props.value}
                           onInput={(e) =>
                               props.oninputCell(props.column.code, props.rowCode, "checkbox", e)}/>
            </td>
        )
    } else if (props.type === "tags_list") {
        return(
            <td contentEditable={false} style={styles.tableCell}>
            </td>
        )
        /*return(
            <td contentEditable={false} style={styles.tableCell}
                onClick={() => props.setTagValue(true, props.rowCode, props.column.code, props.value)}
                onDoubleClick={() => props.setTagValue(true, props.rowCode, props.column.code, props.value)} >
                { getTags(props.value) }
            </td>
        )*/
    }
}